<!-- 关于我们/企业简介-->
<template>
    <div class="recruit-agent">
      <div class="banner pc-banner">
        <div class="title">
          <div class="top">
            <div>全国诚招</div>
            <div>宝乡通合伙人</div>
          </div>
          <div class="bottom">
            <div>覆盖农资行业七大类产品：</div>
            <ul>
              <li v-for="item in productList" :key="item">{{item}}</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="banner-mobile">
        <img src="@/assets/images/recruit/banner-bg-mobile2.png" class="gn-full">
      </div>
      <div class="abstract">
        <div class="text">
          <div class="text_item" style="margin-bottom: 17px;">
            <div>1</div>
            <div>省级合伙人：有农业行业经验，有良好的政商资源，有服务乡梓的情怀。</div>
          </div>
          <div class="text_item">
            <div>2</div>
            <div>运营服务商：一市（地级）一名，有农资行业经验，当地有团队、有仓库、有配送能力</div>
          </div>
        </div>
        <div class="title">以农为本，合作共赢！</div>
        <div class="card">
          <div class="card_item">
            <img src="@/assets/images/recruit/left.png" alt="">
            <div class="card_title">运营服务商</div>
            <div class="card_text pcdisp">
              <p>有团队</p>
              <p>有500m²以上仓库</p>
              <p>有农资行业资源或经验</p>
            </div>
            <div class="card_text appdisp">
              <p>有团队，有500m²以上仓库，有农资行业资源或经验</p>
            </div>
          </div>
          <div class="card_item">
            <img src="@/assets/images/recruit/right.png" alt="">
            <div class="card_title">宝乡通VIP会员</div>
            <div class="card_text pcdisp">
              <p>一个宝乡通电子智能助手</p>
              <p>本地社区化配送网店</p>
              <p>一辆电动三轮车或货车</p>
            </div>
            <div class="card_text appdisp">
              <p>一个宝乡通电子智能助手，本地社区化配送网店，一辆电动三轮车或货车</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 合作收益 -->
      <div class="cooperation_benefits">
        <div class="title gn-flex-between">
          <div class="left-line"></div>
          合作商收益
          <div class="right-line"></div>
        </div>
        <ul>
          <li v-for="item in cooperationList" :key="item.title">
            <div class="img">
              <img :src="item.url" alt="">
            </div>
            <div class="text pcdisp">{{item.title}}</div>
            <div class="text appdisp" style="white-space: nowrap;">{{item.title1}}</div>
            <div class="text1 appdisp">{{item.title2}}</div>
          </li>
        </ul>
      </div>
    </div>
  </template>

<script>
export default {
  name: 'recruit-agent',
  data () {
    return {
      cooperationList: [
        { url: require('@/assets/images/recruit/1.png'), title: '区域垄断赋能收益', title1: '区域垄断', title2: '赋能收益' },
        { url: require('@/assets/images/recruit/2.png'), title: 'AI植保广告服务收益', title1: 'AI植保广告', title2: '服务收益' },
        { url: require('@/assets/images/recruit/3.png'), title: '淡季收储收益', title1: '淡季收储', title2: '收益' },
        { url: require('@/assets/images/recruit/4.png'), title: '虚拟云仓运营收益', title1: '虚拟云仓', title2: '运营收益' },
        { url: require('@/assets/images/recruit/5.png'), title: '代储代发收益', title1: '代储代发', title2: '收益' },
        { url: require('@/assets/images/recruit/6.png'), title: '线下线上一体化运营收益', title1: '线下线上一体化', title2: '运营收益' },
        { url: require('@/assets/images/recruit/7.png'), title: '订制自有品牌产品', title1: '订制自有', title2: '品牌产品' },
        { url: require('@/assets/images/recruit/8.png'), title: '电子智能助手推广收益', title1: '电子智能助手', title2: '推广收益' },
        { url: require('@/assets/images/recruit/10.png'), title: '股(期权)收益', title1: '股(期权)', title2: '收益' },
        { url: require('@/assets/images/recruit/9.png'), title: '厂、商、店三位一体联动促销收益', title1: '厂、商、店三位一体', title2: '联动促销收益' }
      ],
      productList: ['农药', '肥料', '种子', '农机', '农具', '配套包材', '化工']
    }
  }
}
</script>

  <style scoped lang="scss">
  .banner-mobile{
    display: none;
  }

  .recruit-agent{
    margin-top: 68px;
    color: #333333;
    .banner{
      margin: 0 auto;
      height: 453px;
      background-size: cover;
      background-image: url('../../../assets/images/recruit/recruit_banner.png');
      .title{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 100px 100px 100px 0px;
        margin-left: 1103px;
        height: 100%;
        color: #fff;
        box-sizing: border-box;
        .top{
          div:nth-child(1){
            font-size: 60px;
            font-weight: 500;
          }
          div:nth-child(2){
            font-size: 36px;
          }
        }
        .bottom{
          div{
            font-size: 18px;
          }
          ul{
            display: flex;
            font-size: 18px;
            li{
              margin-right: 16px;
              padding: 3px 10px;
              line-height: 18px;
              border: 1px solid #fff;
              border-radius: 5px;
              text-align: center;
            }
          }
        }
      }
    }
    .abstract{
      margin: 0 auto;
      padding: 75px 0px 93px 0px;
      width: 1440px;
      box-sizing: border-box;
      color: #333333;
      .text{
        margin: 0 auto;
        width: 1209px;
        font-size: 24px;
        line-height: 42px;
        .text_item{
          display: flex;
          align-items: center;
          div:nth-child(1){
            margin-right: 12px;
            width: 33px;
            height: 33px;
            border-radius: 50%;
            background-color: #AC3215;
            font-size: 28px;
            font-weight: 500;
            text-align: center;
            line-height: 33px;
            color: #ffffff;
          }
          div:nth-child(2){
            font-size: 30px;
            font-family: Source Han Sans CN, Source Han Sans CN-Medium;
            font-weight: 500;
            text-align: justifyLeft;
            color: #333333;
          }
        }
      }
      .title{
        padding: 100px 0px 120px 0px;
        font-size: 70px;
        font-weight: 600;
        text-align: center;
        box-sizing: border-box;
      }
      .card{
        display: flex;
        justify-content: space-between;
        width: 100%;
        .card_item{
          width: 707px;
          height: 612px;
          background-color: #E5E5E5;
          img{
            width: 707px;
            height: 380px;
          }
          .card_title{
            padding: 34px 30px 10px 60px;
            font-size: 36px;
            font-family: Source Han Sans CN, Source Han Sans CN-Bold;
            font-weight: 700;
            box-sizing: border-box;
          }
          .card_text{
            padding: 0px 60px 20px 60px;
            font-size: 24px;
            font-family: Source Han Sans CN, Source Han Sans CN-Regular;
            font-weight: 400;
            line-height: 34px;
            box-sizing: border-box;
          }
        }
      }
    }
    .cooperation_benefits{
      margin: 0 auto;
      width: 1440px;
      .title{
        width: 100%;
        text-align: center;
        font-size: 36px;
        font-weight: 600;
      }
      ul{
        margin-top: 40px;
        margin-bottom: 139px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 100%;
        height: 446px;
        li{
          padding: 20px 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: calc(1440px / 5);
          box-sizing: border-box;
          text-align: center;
          div:nth-child(1){
            width: 128px;
            height: 128px;
            border-radius: 50%;
          }
          div:nth-child(2){
            margin-top: 20px;
            font-size: 22px;
            font-weight: 600;
          }
          img{
            width: 128px;
            height: 128px;
            border-radius: 50%;

          }
        }
      }
    }
  }
  </style>

  <style scoped lang='scss'>
  // 手机版 样式
  @import './media';
  </style>
